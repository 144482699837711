<script setup>
import { useSsrFetch } from "~/composables/useFetch";
import { useMetaStore } from '~/stores/meta.store'

const developers = ref([]);
developers.value = await useSsrFetch(`pages/developers`);


const metaStore = useMetaStore();
metaStore.setMeta(developers.value.meta);
</script>

<template>
    <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="desktop-default">
        <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-8">
            <div class="m-auto w-full block text-center font-bold text-3xl text-indigo-900 my-10 uppercase tracking-widest">Developers</div>
            <div class="grid grid-cols-4 gap-8">
                <div v-for="developer in developers.data" class="">
                    <NuxtLink :to="localePath({ name: 'developers.show', params: { slug: developer.slug } })">
                        <div class="bg-white rounded-lg h-56 p-3 text-center m-auto w-full flex flex-col items-center justify-center shadow-sm hover:shadow-md">
                            <img :src="developer.logo" class="object-contain h-32 w-32 mb-2" />
                             <h3 class="text-indigo-900 text-xl font-medium">{{ developer.name }}</h3>
                            <h3 class="text-gray-500">{{ $t('client.projects') }}: {{ developer.projects_count }}</h3>
                        </div>
                    </NuxtLink>
                </div>
            </div>
        </div>

    </NuxtLayout>
</template>